import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from '../styles/ContactUs.module.css';

const ContactUs: React.FC = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const emailData = {
            Messages: [
                {
                    From: {
                        Email: process.env.REACT_APP_SENDER_EMAIL!,
                        Name: 'Tech, The Handyman',
                    },
                    To: [
                        {
                            Email: process.env.REACT_APP_RECIPIENT_EMAIL!,
                            Name: 'Abdullah',
                        },
                    ],
                    Subject: 'New Contact Us Message',
                    TextPart: `
                        New Contact Us Message

                        Name: ${formData.name}
                        Email: ${formData.email}
                        Message: ${formData.message}
                    `,
                },
            ],
        };

        try {
            const response = await axios.post('/api/sendEmail', { emailData });
            navigate('/thank-you');
        } catch (error) {
            console.error('Failed to send email:', error);
        }
    };

    return (
        <div className={styles.contactContainer}>
            <h2>Contact Us</h2>
            <form className={styles.form} onSubmit={handleSubmit}>
                <div className={styles.formGroup}>
                    <label htmlFor="name" className={styles.label}>
                        Name<span className={styles.required}>*</span>
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                        className={styles.input}
                    />
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="email" className={styles.label}>
                        Email Address<span className={styles.required}>*</span>
                    </label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                        className={styles.input}
                    />
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="message" className={styles.label}>
                        Message<span className={styles.required}>*</span>
                    </label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        required
                        className={styles.textarea}
                        rows={10}
                    />
                </div>
                <button type="submit" className={styles.button}>
                    Submit
                </button>
            </form>
        </div>
    );
};

export default ContactUs;