// FAQ.tsx

import React from 'react';
import styles from '../styles/FAQ.module.css';

const faqs = [
  {
    question: "What types of services do you offer?",
    answer: "We offer a wide range of handyman services including furniture assembly, TV mounting, appliance installation, drywall repair, fence repair, light fixture installation, and much more."
  },
  {
    question: "How do I book a service?",
    answer: "You can book a service through our website by filling out the booking form, or you can call us directly to schedule an appointment."
  },
  {
    question: "What are your rates?",
    answer: "Our rates vary depending on the type of service and the scope of the work. Please contact us for a detailed quote."
  },
  {
    question: "Are your services guaranteed?",
    answer: "Yes, we stand by the quality of our work and offer a satisfaction guarantee on all services provided."
  },
  {
    question: "Do you offer emergency services?",
    answer: "Yes, we offer emergency handyman services for urgent repairs. Please contact us directly to inquire about availability."
  },
  {
    question: "Are you insured?",
    answer: "Yes, we are fully insured to ensure the safety and protection of our clients and their property."
  },
  {
    question: "How do I prepare for your visit?",
    answer: "Please ensure that the area where the work is to be performed is clean and accessible. Remove any obstacles or items that might be in the way."
  },
  {
    question: "Can I request a specific time for the service?",
    answer: "Yes, you can request a specific time for the service when booking. We will do our best to accommodate your preferred time slot."
  }
];

const FAQ: React.FC = () => {
  return (
    <div className={styles.faq}>
      <h2>Frequently Asked Questions</h2>
      <div className={styles['faq-list']}>
        {faqs.map((faq, index) => (
          <div key={index} className={styles['faq-item']}>
            <h3>{faq.question}</h3>
            <p>{faq.answer}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;