import React from 'react';
import { Helmet } from 'react-helmet';
import styles from '../styles/Home.module.css';

const Home: React.FC = () => {
  return (
    <div className={styles.home}>
      <Helmet>
        <title>Handyman Services in Houston | Tech The Handyman</title>
        <meta name="description" content="Tech The Handyman offers professional handyman services in Houston and its surroundings. Contact us today for a free quote." />
        <link rel="canonical" href="https://www.techthehandyman.com/" />
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Tech The Handyman",
            "description": "Professional handyman services in Houston.",
            "url": "https://www.techthehandyman.com/",
            "telephone": "+13465035226",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+13465035226",
              "contactType": "Customer Service"
            }
          }
          `}
        </script>
      </Helmet>
      <section className={styles.hero}>
        <div className={styles.parallax}></div>
        <div className={styles.heroOverlay}>
          <div className={styles.heroText}>
            <h1>Welcome to Tech, The Handyman!</h1>
            <p>Your one-stop solution for all handyman services.</p>
            <p>From repairs to installations, we've got you covered.</p>
            <p>We proudly service <span className={styles.highlightedText}>Houston and its surroundings</span>.</p>
            <p>Contact us today and get a free quote for your project.</p>
          </div>
        </div>
      </section>

      <section className={styles.additionalContent}>
        <div className={styles.container}>
          <h2>Why Choose Us?</h2>
          <p>We provide top-notch handyman services to make your life easier. From home repairs to installations, our expert team is here to help.</p>
          
          <div className={styles.features}>
            <div className={styles.feature}>
              <h3>Professional Service</h3>
              <p>Our team is highly trained and experienced, ensuring the best quality of service for all your needs.</p>
            </div>
            <div className={styles.feature}>
              <h3>Reliable Support</h3>
              <p>We are available 24/7 to provide support and answer any questions you may have.</p>
            </div>
            <div className={styles.feature}>
              <h3>Affordable Prices</h3>
              <p>We offer competitive pricing without compromising on quality. Get the best value for your money.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
