import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/ThankYou.module.css';

const ThankYou: React.FC = () => {
  return (
    <div className={styles['thank-you-container']}>
      <h2 className={styles['thank-you-heading']}>Thank you for your submission!</h2>
      <p className={styles['thank-you-message']}>We have received your inquiry and will get back to you shortly.</p>
      <Link to="/" className={styles['thank-you-link']}>
        Back to Home
      </Link>
    </div>
  );
};

export default ThankYou;
