import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import styles from '../styles/Footer.module.css';

const Footer: React.FC = () => {
  const whatsappUrl = "https://wa.me/13465035226";
  const phoneNumber = "+1 (346) 503-5226";
  const emailAddress = "info@TechTheHandyman.com";

  const handleCallClick = () => {
    window.location.href = `tel:${phoneNumber.replace(/\s/g, '')}`;
  };

  const handleEmailClick = () => {
    window.location.href = `mailto:${emailAddress}`;
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.section}>
          <h3>Contact Us</h3>
          <div className={styles.contactDetails} onClick={handleEmailClick}>
            <FontAwesomeIcon icon={faEnvelope} />
            <p>{emailAddress}</p>
          </div>
          <div className={styles.contactDetails} onClick={handleCallClick}>
            <FontAwesomeIcon icon={faPhone} />
            <p>{phoneNumber}</p>
          </div>
          <div className={styles.contactDetails}>
            <FontAwesomeIcon icon={faMapMarkerAlt} />
            <p className={styles.location}>Houston, Texas</p>
          </div>
        </div>

        <div className={styles.section}>
          <h3>Follow Us</h3>
          <div className={styles.socialIcons}>
            <a href="https://www.facebook.com/profile.php?id=61560870542626" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} />
              Facebook
            </a>
            <a href="https://x.com/TechTheHandyman" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter} />
              Twitter
            </a>
            <a href="https://www.instagram.com/techthehandyman/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} />
              Instagram
            </a>
            <a href={whatsappUrl} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faWhatsapp} />
              WhatsApp
            </a>
          </div>
        </div>

        <div className={styles.section}>
          <h3>Additional Links</h3>
          <ul className={styles.linkList}>
            <li><Link to="/termsOfService">Terms of Service</Link></li>
            <li><Link to="/faq">FAQs</Link></li>
          </ul>
        </div>
      </div>

      <p className={styles.copyright}>© 2024 Handyman. All Rights Reserved.</p>
    </footer>
  );
};

export default Footer;
