// ServiceCard.tsx

import React from 'react';
import styles from '../styles/ServiceCard.module.css';

interface ServiceCardProps {
  title: string;
  description: string;
  image: string;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ title, description, image }) => {
  return (
    <div className={styles['service-card']}>
      <img src={image} alt={title} className={styles['card-image']} />
      <div className={styles['card-content']}>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default ServiceCard;
