import React from 'react';
import ServiceCard from '../components/ServiceCard';
import styles from '../styles/Services.module.css';
import furnitureAssemblyImage from '../assets/furniture.jpg'
import smartHomeImage from '../assets/smart.png'
import applianceInstallationImage from '../assets/appliance.jpg'
import blindsInstallationImage from '../assets/blinds.png'
import caulkingImage from '../assets/caulking.jpg'
import ceilingRepairImage from '../assets/ceiling.jpg'
import drywallRepairImage from '../assets/drywall.jpg'
import ceilingFanImage from '../assets/ceilingFan.jpg'
import fenceRepairImage from '../assets/fence.jpg'
import garbageDisposalImage from '../assets/garbageDisposal.png'
import tvImage from '../assets/TV.jpg'
import mailboxImage from '../assets/mailbox.jpg'
import petImage from '../assets/pet.jpg'
import smokeDetectorImage from '../assets/smokeDetector.jpg'
import lightsImage from '../assets/lights.jpg'
import shelfImage from '../assets/shelving.jpg'

const services = [
  {
    title: "Furniture Assembly",
    description: "Expert assembly of furniture items from various retailers.",
    image: furnitureAssemblyImage
  },
  {
    title: "TV Mounting",
    description: "Secure and professional mounting of televisions on any wall surface.",
    image: tvImage // Replace with appropriate image import
  },
  {
    title: "Appliance Installation",
    description: "Installation of various home appliances with precision and safety.",
    image: applianceInstallationImage // Replace with appropriate image import
  },
  {
    title: "Drywall Repair",
    description: "Efficient repair and patching of drywall to restore smooth surfaces.",
    image: drywallRepairImage // Replace with appropriate image import
  },
  {
    title: "Fence Repair",
    description: "Repair and reinforcement of fences to enhance durability.",
    image: fenceRepairImage// Replace with appropriate image import
  },
  {
    title: "Light Fixture Installation",
    description: "Installation and replacement of light fixtures to illuminate your space.",
    image: lightsImage // Replace with appropriate image import
  },
  {
    title: "Pet Door Installation",
    description: "Installation of pet doors for convenient access for your furry friends.",
    image: petImage// Replace with appropriate image import
  },
  {
    title: "Shelving Installation",
    description: "Custom shelving solutions tailored to your storage needs.",
    image: shelfImage // Replace with appropriate image import
  },
  {
    title: "Ceiling Fan Installation",
    description: "Installation of ceiling fans to improve air circulation and comfort.",
    image: ceilingFanImage // Replace with appropriate image import
  },
  {
    title: "Caulking & Sealing",
    description: "Sealing gaps and cracks to improve energy efficiency and aesthetics.",
    image: caulkingImage // Replace with appropriate image import
  },
  {
    title: "Blind & Curtain Installation",
    description: "Installation of blinds and curtains to enhance privacy and aesthetics.",
    image: blindsInstallationImage // Replace with appropriate image import
  },
  {
    title: "Smoke & CO Detector Installation",
    description: "Installation of detectors for early warning against smoke and carbon monoxide.",
    image: smokeDetectorImage // Replace with appropriate image import
  },
  {
    title: "Garbage Disposal Installation",
    description: "Professional installation of garbage disposals for efficient waste management.",
    image: garbageDisposalImage // Replace with appropriate image import
  },
  {
    title: "Mailbox Installation",
    description: "Installation of mailboxes to secure your incoming mail.",
    image: mailboxImage // Replace with appropriate image import
  },
  {
    title: "Ceiling & Wall Repairs",
    description: "Repair and restoration of ceilings and walls to pristine condition.",
    image: ceilingRepairImage // Replace with appropriate image import
  },
  {
    title: "Smart Home Device Installation",
    description: "Installation and setup of smart home devices for enhanced convenience.",
    image: smartHomeImage // Replace with appropriate image import
  }
];

const Services: React.FC = () => {
  return (
    <div className={styles.services}>
      <h2>Our Services</h2>
      <div className={styles['service-cards']}>
        {services.map((service, index) => (
          <ServiceCard
            key={index}
            title={service.title}
            description={service.description}
            image={service.image}
          />
        ))}
      </div>
    </div>
  );
};

export default Services;
