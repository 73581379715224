import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from '../styles/RequestService.module.css';

const RequestService: React.FC = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: '',
        address1: '',
        address2: '',
        phoneNumber: '',
        email: '',
        service: '',
        additionalInformation: '',
    });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const emailData = {
            Messages: [
                {
                    From: {
                        Email: process.env.REACT_APP_SENDER_EMAIL!,
                        Name: 'Tech, The Handyman',
                    },
                    To: [
                        {
                            Email: process.env.REACT_APP_RECIPIENT_EMAIL!,
                            Name: 'Abdullah',
                        },
                    ],
                    Subject: 'New Service Request',
                    TextPart: `
                        New Service Request

                        Name: ${formData.name}
                        Address 1: ${formData.address1}
                        Address 2: ${formData.address2}
                        Phone Number: ${formData.phoneNumber}
                        Email: ${formData.email}
                        Service: ${formData.service}
                        Additional Information: ${formData.additionalInformation}
                    `,
                },
            ],
        };

        try {
            const response = await axios.post('/api/sendEmail', { emailData });
            navigate('/thank-you');
        } catch (error) {
            console.error('Failed to send email:', error);
        }
    };

    return (
        <div className={styles.requestServiceContainer}>
            <h2>Request a Service Quote</h2>
            <form className={styles.form} onSubmit={handleSubmit}>
                <div className={styles.formGroup}>
                    <label htmlFor="name" className={styles.label}>
                        Name<span className={styles.required}>*</span>
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                        className={styles.input}
                    />
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="address1" className={styles.label}>
                        Address Line 1<span className={styles.required}>*</span>
                    </label>
                    <input
                        type="text"
                        id="address1"
                        name="address1"
                        value={formData.address1}
                        onChange={handleInputChange}
                        required
                        className={styles.input}
                    />
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="address2" className={styles.label}>
                        Address Line 2
                    </label>
                    <input
                        type="text"
                        id="address2"
                        name="address2"
                        value={formData.address2}
                        onChange={handleInputChange}
                        className={styles.input}
                    />
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="phoneNumber" className={styles.label}>
                        Phone Number<span className={styles.required}>*</span>
                    </label>
                    <input
                        type="tel"
                        id="phoneNumber"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleInputChange}
                        required
                        className={styles.input}
                    />
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="email" className={styles.label}>
                        Email Address<span className={styles.required}>*</span>
                    </label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                        className={styles.input}
                    />
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="service" className={styles.label}>
                        Requested Service<span className={styles.required}>*</span>
                    </label>
                    <select
                        id="service"
                        name="service"
                        value={formData.service}
                        onChange={handleInputChange}
                        required
                        className={styles.select}
                    >
                        <option value="">Select a service</option>
                        <option value="Appliance Installation">Appliance Installation</option>
                        <option value="Blind & Curtain Installation">Blind & Curtain Installation</option>
                        <option value="Caulking & Sealing">Caulking & Sealing</option>
                        <option value="Ceiling & Wall Repairs">Ceiling & Wall Repairs</option>
                        <option value="Ceiling Fan Installation">Ceiling Fan Installation</option>
                        <option value="Drywall Repair">Drywall Repair</option>
                        <option value="Fence Repair">Fence Repair</option>
                        <option value="Furniture Assembly">Furniture Assembly</option>
                        <option value="Garbage Disposal Installation">Garbage Disposal Installation</option>
                        <option value="Light Fixture Installation">Light Fixture Installation</option>
                        <option value="Mailbox Installation">Mailbox Installation</option>
                        <option value="Pet Door Installation">Pet Door Installation</option>
                        <option value="Shelving Installation">Shelving Installation</option>
                        <option value="Smoke & CO Detector Installation">Smoke & CO Detector Installation</option>
                        <option value="Smart Home Device Installation">Smart Home Device Installation</option>
                        <option value="TV Mounting">TV Mounting</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="additionalInformation" className={styles.label}>
                        Additional Information
                    </label>
                    <textarea
                        id="additionalInformation"
                        name="additionalInformation"
                        value={formData.additionalInformation}
                        onChange={handleInputChange}
                        className={styles.textarea}
                        rows={10}
                    />
                </div>
                <button type="submit" className={styles.button}>
                    Submit
                </button>
            </form>
        </div>
    );
};

export default RequestService;
