import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/Navbar.module.css';

const Navbar: React.FC = () => {
  return (
    <nav className={styles.navbar}>
      <div className={styles.logo}>
        <Link to="/" className={styles.logoLink}>
          <span className={styles.title}>Tech, The Handyman!</span>
        </Link>
      </div>
      <div className={styles.linksContainer}>
        <Link to="/services" className={styles.link}>
          Services
        </Link>
        <Link to="/faq" className={styles.link}>
          FAQs
        </Link>
        <Link to="/about" className={styles.link}>
          About Us
        </Link>
        <Link to="/contactUs" className={styles.link}>
          Contact Us
        </Link>
      </div>
      <div className={styles.contactContainer}>
        <Link to="/requestService" className={`${styles.link} ${styles.highlight}`}>
          Request a Service Quote
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
