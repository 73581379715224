import React from 'react';
import styles from '../styles/TermsOfService.module.css';

const TermsOfService: React.FC = () => {
  return (
    <div className={styles.termsContainer}>
      <div className={styles.termsContent}>
        <h1>Terms of Service</h1>
        <p>Welcome to Tech, The Handyman. By using our services, you agree to the following terms and conditions:</p>

        <h2>1. Service Agreement</h2>
        <p>We guarantee the quality of our work. If you are not satisfied with the service provided, please contact us within 30 days, and we will make it right.</p>

        <h2>2. User Responsibilities</h2>
        <p>Users must provide accurate information when requesting services. Failure to do so may result in the inability to complete the requested services.</p>

        <h2>3. Payment Terms</h2>
        <p>Payments are due upon completion of services unless otherwise agreed upon.</p>

        <h2>4. Cancellation Policy</h2>
        <p>We kindly request a 24-hour notice for cancellations or rescheduling of appointments.</p>

        <h2>5. Limitation of Liability</h2>
        <p>Tech, The Handyman is not liable for any indirect, incidental, or consequential damages arising from the use of our services.</p>

        <h2>6. Changes to Terms</h2>
        <p>We reserve the right to update these terms at any time. Users will be notified of any significant changes to the terms of service.</p>

        <h2>7. Contact Information</h2>
        <p>If you have any questions or concerns about these terms, please contact us at info@techthehandyman.com.</p>
      </div>
    </div>
  );
};

export default TermsOfService;