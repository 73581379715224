import React from 'react';
import styles from '../styles/AboutUs.module.css';

const AboutUs: React.FC = () => {
  return (
    <div className={styles.aboutUs}>
      <div className={styles.content}>
        <section className={styles.story}>
          <h2>Our Story</h2>
          <p>
            Welcome to Tech the Handyman! My name is Abdullah, and I am the passionate founder behind this business. From a young age, I was always fascinated with the art of DIY projects. What started as a hobby of fixing and creating things for myself and my close ones, soon blossomed into a full-fledged business.
          </p>
          <p>
            Over the years, I honed my skills and developed a keen eye for detail. I realized that many people were in need of reliable and meticulous handyman services, and that's when I decided to turn my passion into a profession. With a strong commitment to quality and customer satisfaction, I strive to ensure that every job is done right the first time, leaving no room for problems later on.
          </p>
        </section>

        <section className={styles.mission}>
          <h2>Our Mission</h2>
          <p>
            Our mission at Tech the Handyman is to provide top-notch handyman services that exceed our clients' expectations. We believe in the importance of building trust and long-lasting relationships with our customers. By paying attention to the smallest details and delivering exceptional results, we aim to be your go-to solution for all your handyman needs.
          </p>
        </section>

        <section className={styles.values}>
          <h2>Our Values</h2>
          <ul>
            <li><strong>Passion:</strong> We love what we do, and it shows in our work.</li>
            <li><strong>Quality:</strong> We are committed to delivering the highest standards of craftsmanship.</li>
            <li><strong>Reliability:</strong> You can count on us to get the job done right and on time.</li>
            <li><strong>Customer Satisfaction:</strong> Your happiness is our top priority.</li>
            <li><strong>Attention to Detail:</strong> We believe that the little things make a big difference.</li>
          </ul>
        </section>

        <section className={styles.expertise}>
          <h2>Our Expertise</h2>
          <p>
            With years of experience in the handyman industry, I bring a wealth of knowledge and skills to every project. Whether it's furniture assembly, TV mounting, appliance installation, drywall repair, or any other handyman task, I approach each job with the same level of dedication and precision.
          </p>
          <p>
            My goal is to provide you with peace of mind, knowing that your home is in good hands. I take pride in my work and always strive for perfection, ensuring that every project is completed to the highest standards.
          </p>
        </section>
      </div>
    </div>
  );
};

export default AboutUs;
